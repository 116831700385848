import type { UserContextValue } from '@devias/contexts/auth/types';
import { UserContext } from '@devias/contexts/auth/user-context';
import * as React from 'react';

export function useUser(): UserContextValue {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}
